import $ from 'jquery';
import slick from 'slick-carousel';

// import Swiper JS
import(/* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ 'root/utils/swiper.js');
import { fontAwesomeLoad } from "root/utils/fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";




if (document.body.contains(document.querySelector(".description-box"))) 
{

var buttons = document.querySelectorAll(".tab .readmore-link");
var descriptionBox = document.querySelectorAll(".description-box");


buttons.forEach((button) => {
  button.addEventListener("click", function () {
    var target = button.dataset.target;

    buttons.forEach((buttonClick) => {
      buttonClick.classList.remove("active");
      this.classList.add("active");
    }); 
    descriptionBox.forEach((box) => {
      box.classList.remove("active");
      document.getElementById(target).classList.add("active");
    });
  });
});
}

$(function () {
  $('.carousel').slick({
    slidesToShow: 4,
    loop: true,
    centerMode: true,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    });
    $('.four-content').slick({
      slidesToShow: 4,
      loop: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
      });
    $('.news').slick({
      infinite: true,
      slidesToShow: 3, // Shows a three slides at a time
      slidesToScroll: 1, // When you click an arrow, it scrolls 1 slide at a time
      arrows: true, // Adds arrows to sides of slider
      dots: true, // Adds the dots on the bottom
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    });
});